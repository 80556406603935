<template>
  <div>
    <el-tabs v-model="activeName">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="使用月份">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="7">
            <el-input placeholder="使用状态">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primay" @click="getBankAnticipateList()">查询</el-button>
            <el-button type="primay">重置</el-button>
          </el-col>
        </el-row>
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin:9px 2px">
          <el-button type="danger" icon="el-icon-add" size="min">新增使用</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">修改</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">调整</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">借出记录</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">存款列表</el-button>
        </el-row>
        <!-- 列表区域 -->
        <el-table :data="bankAnticipateList" border stripe>
          <el-table-column label="序号" align="center" width="65">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="支行名称" prop="subbranch" />
          <el-table-column label="使用人" prop="userName" />
          <el-table-column label="使用月份" prop="useMonth" />
          <el-table-column label="归还月份" prop="borrowMonth" />
          <el-table-column label="使用金额" prop="borrowMoneyFirst" />
          <el-table-column label="使用原因" prop="borrowCauseFirst" />
          <el-table-column label="状态" prop="anticipateStatus" />
        </el-table>
      </el-card>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "bankAnticipate",
  data() {
    return {
      //默认第一个选项卡
      activeName: "first",
      bankAnticipateList: [],
      id: null,
      radio: null
    };
  },
  mounted() {
    this.getBankAnticipateList();
  },
  methods: {
    async getBankAnticipateList() {
      const { data: res } = await this.$http.get(
        "xun/fund/xunBankAnticipate/list",
        {
          params: this.queryParam
        }
      );
      if (res.code !== '200') return this.$message.error("获取基金信息失败");
      this.bankAnticipateList = res.result;
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log(row);
      this.id = row.id;
      this.templateSelection = row;
    }
  }
};
</script>
